<template>
   <div class="cont">
      <Breadcrumb :crumbs="crumbs"/>
      <div class="content-box">
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>活动名称：</label>
                  <el-input v-model="params.name" placeholder="活动名称" clearable></el-input>
               </el-row>
               <el-button @click="handleQuery(true)" class="bg-gradient" type="primary" icon="el-icon-search">搜索</el-button>
               <el-button @click="handleQuery(false)" icon="el-icon-refresh-right" plain>重置</el-button>
            </el-row>
         </el-row>
         <el-row class="table-box">
            <el-row type="flex" justify="space-between">
               <el-tabs v-model="params.status">
                  <el-tab-pane label="全部" name=""></el-tab-pane>
                  <el-tab-pane label="开启中" name="YES"></el-tab-pane>
                  <el-tab-pane label="已暂停" name="NO"></el-tab-pane>
               </el-tabs>
               <el-row class="right-btn">
                  <el-button class="bg-gradient" type="primary" @click="handleAddEdit('add')">创建活动</el-button>
               </el-row>
            </el-row>
            <el-table
                v-loading="loading"
                :data="tableData"
                style="width: 100%"
                :stripe="true"
                border fit>
               <el-table-column label="序号" width="60">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
              <el-table-column min-width="50">
                <template slot-scope="scope">
                  <el-image :src="scope.row.qrCode" :preview-src-list="[scope.row.qrCode]" fit="fill" alt="渠道码"></el-image>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="活动名称" min-width="70"></el-table-column>
              <!-- <el-table-column label="客户数" min-width="70"></el-table-column>-->
              <el-table-column prop="userName" label="分配员工" min-width="70"></el-table-column>
              <el-table-column label="所属标签">
                <template slot-scope="scope" v-if="scope.row.employeeCodeTags">
                  <el-tag class="m-bottom-5" type="mini" v-for="(tag, index) in scope.row.employeeCodeTags" :key="index">{{ tag.tagName }}</el-tag>
                </template>
              </el-table-column>
                <!--<el-table-column label="状态">
                 <template slot-scope="scope">
                   {{ scope.row.status | filterStatus(that) }}
                 </template>
               </el-table-column>-->
               <el-table-column prop="createTime" label="创建时间"></el-table-column>
               <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button @click="handleAddEdit('edit', scope.row)" type="text">
                      <el-link type="primary">编辑</el-link>
                    </el-button>
                    <el-button @click="handleDel(scope.row.id)" type="text">
                      <el-link type="danger">删除</el-link>
                    </el-button>
                  </template>
               </el-table-column>
            </el-table>
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePagNum" @handleCurrentChangeSub="changeCurrPage"/>
         </el-row>
      </div>
   </div>
</template>
<script>
import { getPage, removeEmployeeCode } from "@/api/data/wechat/liveCode";
import { getDict } from "@/common/js/common";
import { mapState } from "vuex";
export default {
  name: 'liveCode',
  data() {
    return {
      that: this,
      crumbs: new Map([
        ['数据中台', ''], ['企业微信', ''], ['渠道活码', '']
      ]),
      tableData: [],
      params: {
        name: '', status: ''
      },
      loading: true,
      total: 0,
      limit: 0,
      page: 1,
    }
  },
  computed: {
    ...mapState(['dictData', 'hotelInfo'])
  },
  mounted() {
    getDict('Ew-channel-code-status')
    this.limit = sessionStorage.getItem('pageSize') * 1
    this.getEmployeeCode()
  },
  methods: {
    // 获取渠道码列表
    getEmployeeCode() {
      const query = `?limit=${ this.limit }&page=${ this.page }`,
            params = { ...this.params, hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId };
      params.status === '0' && (params.status = '')
      getPage(query, params).then(({ success, records, total }) => {
        if (!success) return
        this.loading = false
        this.total = total
        this.tableData = records
      })
    },
    // 处理查询
    handleQuery(bool) {
      if (bool) return this.getEmployeeCode()
      for (const k in this.params) this.params[k] = ''
    },
    // 改变每页数
    changePagNum(num) {
      this.limit = num
      this.getEmployeeCode()
    },
    // 改变当前页
    changeCurrPage(num) {
      this.page = num
      this.getEmployeeCode()
    },
    // 创建活动
    handleAddEdit(action, row) {
      if (action === 'edit') sessionStorage.setItem('activityInfo', JSON.stringify(row))
      this.$router.push({ path: '/enterprise_wechat/live_code/create', query: { action } })
    },
    // 删除渠道码
    handleDel(id) {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        removeEmployeeCode({ id }).then(({ success }) => {
          if (!success) return
          this.getEmployeeCode()
          this.$message({ message: '删除成功！', type: 'success' })
        })
      }).catch(_ => {})
    }
  },
  filters: {
    filterStatus(val, that){
      if (val && that.dictData['Ew-channel-code-status']) return that.dictData['Ew-channel-code-status'][val] || val
    },
  }
}
</script>
<style lang="scss" scoped>
.el-image{ width: 50px }
</style>
